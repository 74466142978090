import React from "react"
import Layout from "../components/layouts/Layout.js"
import { graphql, useStaticQuery } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../components/GatsbyLink"
import Button from "../components/Button.js"
import icon from "../images/link.png"
import * as styles from "../stylesheets/pages/client-links.module.scss"

const ClientLinks = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicClientLinks {
        data {
          description {
            richText
          }
          link_container {
            link_title {
              text
            }
            link_url {
              url
            }
            link_description {
              richText
              text
            }
          }
          main_image {
            alt
            url
          }
          title {
            text
          }
        }
      }
    }
  `).prismicClientLinks.data

  const links = data.link_container.map((link) => {
    return (
      <a href={link.link_url.url} className={styles.linkContainer}>
        <div className={styles.linkIconContainer}>
          <img src={icon} alt="link icon" className={styles.linkIcon} />
        </div>
        <div className={styles.linkBodyContainer}>
          <h2 className={styles.linkTitle}>{link.link_title.text}</h2>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            className="linkDescription"
          >
            {/* <RichText
              render={link.link_description.richText}
              serializeHyperlink={GatsbyLink}
            /> */}
            <p className={styles.linkDescription}>
              {link.link_description.text}
            </p>
          </div>
        </div>
      </a>
    )
  })

  return (
    <Layout
      title="Client Links - TechCollective"
      description="TechCollective is a worker-owned MSP, providing IT and tech support to businesses."
    >
      <div className={styles.container}>
        <img
          src={data.main_image.url}
          alt={data.main_image.alt}
          className={styles.mainImage}
        />
        <div className={styles.bodyContainer}>
          <h1 className={styles.title}>{data.title.text}</h1>
          <div className={styles.description}>
            <RichText
              render={data.description.richText}
              serializeHyperlink={GatsbyLink}
            />
          </div>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div style={{ paddingTop: "80px" }}> {links}</div>

        <div style={{ marginTop: "100px", marginBottom: "60px" }}>
          <h2>Need help with something else?</h2>
          <Button text="Contact Us" internalLink="/contact" />
        </div>
      </div>
    </Layout>
  )
}

export default ClientLinks
