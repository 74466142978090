// extracted by mini-css-extract-plugin
export var bodyContainer = "client-links-module--bodyContainer--78251";
export var bottomContainer = "client-links-module--bottomContainer--1c477";
export var container = "client-links-module--container--91576";
export var description = "client-links-module--description--77c3b";
export var faq = "client-links-module--faq--5836b";
export var faqDivider = "client-links-module--faq-divider--6a70e";
export var faqIcon = "client-links-module--faq-icon--ab7ac";
export var faqQuestion = "client-links-module--faq-question--42026";
export var linkBodyContainer = "client-links-module--linkBodyContainer--4f25c";
export var linkContainer = "client-links-module--linkContainer--e5764";
export var linkDescription = "client-links-module--linkDescription--c4872";
export var linkIcon = "client-links-module--linkIcon--99546";
export var linkIconContainer = "client-links-module--linkIconContainer--d8771";
export var linkTitle = "client-links-module--linkTitle--b9b18";
export var mainImage = "client-links-module--mainImage--31923";
export var title = "client-links-module--title--707b5";