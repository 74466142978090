import React from "react"
import { Link } from "gatsby"
import * as styles from "../stylesheets/components/button.module.scss"

const Button = ({ text, internalLink, link }) => {
  //const Link = internalLink ? internalLink : link

  return (
    <>
      {internalLink ? (
        <Link to={internalLink} className={styles.container}>
          <button className={styles.button}>{text}</button>
        </Link>
      ) : (
        <button src={link} className={styles.button}>
          {text}
        </button>
      )}
    </>
  )
}

export default Button
